/* Created with http://boockup.marco.land */
@-webkit-keyframes rotatingAnimationX {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
}
@keyframes rotatingAnimationX {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
}
@-webkit-keyframes rotatingAnimationY {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotatingAnimationY {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes rotatingAnimationZ {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes rotatingAnimationZ {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

.container {
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  -webkit-perspective: 1200px;
  perspective: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.container > div {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.boockup {
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(-8deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-8deg);
  pointer-events: none;
}
.book-container {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: 10s rotatingAnimationY linear infinite;
  animation: 10s rotatingAnimationY linear infinite;
}
.book-container > div {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}
.book-top,
.book-bottom,
.book-side-right {
  background-size: auto auto;
  background-repeat: repeat;
  background-position: top left;
}
.book-front {
  background-image: url("/assets/images/download/book-cover.jpg");
  width: 400px;
  height: 500px;
  -webkit-transform: translate3d(-200px, -250px, 6px) translate3d(0, 0, 0)
    rotateY(0deg);
  transform: translate3d(-200px, -250px, 6px) translate3d(0, 0, 0) rotateY(0deg);
}
.book-side-left {
  background-color: white;
  width: 12px;
  height: 500px;
  -webkit-transform: translate3d(-200px, -250px, 6px) translate3d(-6px, 0, -6px)
    rotateY(-90deg);
  transform: translate3d(-200px, -250px, 6px) translate3d(-6px, 0, -6px)
    rotateY(-90deg);
}
.book-side-right {
  background-color: white;
  width: 12px;
  height: 500px;
  -webkit-transform: translate3d(-200px, -250px, 6px)
    translate3d(394px, 0px, -6px) rotateY(90deg);
  transform: translate3d(-200px, -250px, 6px) translate3d(394px, 0px, -6px)
    rotateY(90deg);
}
.book-top {
  background-color: white;
  background-position: bottom right;
  width: 400px;
  height: 12px;
  -webkit-transform: translate3d(-200px, -250px, 6px)
    translate3d(0px, -6px, -6px) rotateX(90deg);
  transform: translate3d(-200px, -250px, 6px) translate3d(0px, -6px, -6px)
    rotateX(90deg);
}
.loading_two {
  color: white;
  width: 33.33vw;
  display: none;
  font-size: 20px;
}

.loading_three {
  color: white;
  width: 33.33vw;
  display: none;
  font-size: 20px;
}

.location {
  font-size: 20px;
}

@media screen and (min-width: 812px) {
  .loading_one {
    display: block;
    width: 33.33vw;
  }
  .loading_two {
    display: block;
    margin-left: 33.33vw;
  }

  .loading_three {
    display: block;
    margin-left: 66.66vw;
  }

  .boockup {
    pointer-events: all;
  }
}
