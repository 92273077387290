@keyframes rotatingAnimationX {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(360deg);
  }
}

@keyframes rotatingAnimationY {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rotatingAnimationZ {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.container {
  width: 100vw;
  height: 100%;
  perspective: 1200px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.container > div {
  transform-style: preserve-3d;
}

.boockup {
  pointer-events: none;
  transform: rotateX(0)rotateY(0)rotateZ(-8deg);
}

.book-container {
  transform-style: preserve-3d;
  animation: 10s linear infinite rotatingAnimationY;
}

.book-container > div {
  backface-visibility: visible;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.book-top, .book-bottom, .book-side-right {
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
}

.book-front {
  width: 400px;
  height: 500px;
  background-image: url("book-cover.8e0e9ee8.jpg");
  transform: translate3d(-200px, -250px, 6px)translate3d(0, 0, 0)rotateY(0);
}

.book-side-left {
  width: 12px;
  height: 500px;
  background-color: #fff;
  transform: translate3d(-200px, -250px, 6px)translate3d(-6px, 0, -6px)rotateY(-90deg);
}

.book-side-right {
  width: 12px;
  height: 500px;
  background-color: #fff;
  transform: translate3d(-200px, -250px, 6px)translate3d(394px, 0, -6px)rotateY(90deg);
}

.book-top {
  width: 400px;
  height: 12px;
  background-color: #fff;
  background-position: 100% 100%;
  transform: translate3d(-200px, -250px, 6px)translate3d(0, -6px, -6px)rotateX(90deg);
}

.loading_two, .loading_three {
  color: #fff;
  width: 33.33vw;
  font-size: 20px;
  display: none;
}

.location {
  font-size: 20px;
}

@media screen and (min-width: 812px) {
  .loading_one {
    width: 33.33vw;
    display: block;
  }

  .loading_two {
    margin-left: 33.33vw;
    display: block;
  }

  .loading_three {
    margin-left: 66.66vw;
    display: block;
  }

  .boockup {
    pointer-events: all;
  }
}

/*# sourceMappingURL=index.304ca8f5.css.map */
